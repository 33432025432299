"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DisplayAnimation {
    static slideUp(target, duration = 300) {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = `${duration}ms`;
        target.style.boxSizing = 'border-box';
        target.style.height = `${target.offsetHeight}px`;
        // Its magic, but without this line animation not working
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = '0';
        target.style.paddingTop = '0';
        target.style.paddingBottom = '0';
        target.style.marginTop = '0';
        target.style.marginBottom = '0';
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }
    static slideDown(target, duration = 300) {
        target.style.removeProperty('display');
        let { display } = window.getComputedStyle(target);
        if (display === 'none') {
            display = 'block';
        }
        target.style.display = display;
        const height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = '0';
        target.style.paddingTop = '0';
        target.style.paddingBottom = '0';
        target.style.marginTop = '0';
        target.style.marginBottom = '0';
        // Its magic, but without this line animation not working
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = `${duration}ms`;
        target.style.height = `${height}px`;
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }
    static slideToggle(target, duration = 300) {
        if (window.getComputedStyle(target).display === 'none') {
            return this.slideDown(target, duration);
        }
        return this.slideUp(target, duration);
    }
    static fadeIn(target, duration = 300) {
        target.style.opacity = '0';
        target.style.removeProperty('display');
        let { display } = window.getComputedStyle(target);
        if (display === 'none') {
            display = 'block';
        }
        target.style.display = display;
        target.style.transitionProperty = 'opacity';
        target.style.transitionDuration = `${duration}ms`;
        window.setTimeout(() => {
            target.style.opacity = '1';
            window.setTimeout(() => {
                target.style.removeProperty('opacity');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        }, 1);
    }
    static fadeOut(target, duration = 300) {
        target.style.transitionProperty = 'opacity';
        target.style.transitionDuration = `${duration}ms`;
        target.style.opacity = '0';
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('opacity');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }
    static hide(target) {
        target.style.display = 'none';
    }
    static show(target) {
        const isTargetHidden = target.offsetParent === null;
        if (isTargetHidden) {
            target.style.display = 'block';
        }
    }
    static toggle(target) {
        if (window.getComputedStyle(target).display === 'none') {
            return this.show(target);
        }
        return this.hide(target);
    }
}
exports.default = DisplayAnimation;
