"use strict";
(() => {
    const searchInput = document.getElementById('services-search-input');
    const table = document.querySelector('.tyre-services-table');
    if (table && searchInput) {
        const tableRows = Array.from(table.querySelectorAll('tbody > tr'));
        searchInput.addEventListener('keyup', () => {
            const value = searchInput.value.toLowerCase();
            tableRows.forEach((row) => {
                var _a, _b;
                const textValue = (_b = (_a = row.textContent) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : null;
                if (textValue && textValue.indexOf(value) > -1) {
                    row.style.display = 'table-row';
                }
                else {
                    row.style.display = 'none';
                }
            });
        });
    }
})();
