"use strict";
(() => {
    Array.from(document.querySelectorAll('a[href^="#"]')).forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
            e.preventDefault();
            const anchorId = anchor.getAttribute('href');
            if (anchorId === null || anchorId === '#') {
                return;
            }
            const element = document.querySelector(anchorId);
            if (element === null) {
                return;
            }
            element.scrollIntoView({
                behavior: 'smooth',
            });
        });
    });
})();
