"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DataLayer {
    constructor() {
        this.dataLayer = window.dataLayer || [];
    }
    push(dataLayerContents) {
        this.dataLayer.push(dataLayerContents);
    }
}
exports.default = DataLayer;
