"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addNavigation = addNavigation;
exports.removeNavigation = removeNavigation;
const disabledClassName = 'disabled';
function addTogglePrevNextButtonsActive(embla, prevButton, nextButton) {
    function togglePrevNextButtonsState() {
        if (embla.canScrollPrev()) {
            prevButton.classList.remove(disabledClassName);
        }
        else {
            prevButton.classList.add(disabledClassName);
        }
        if (embla.canScrollNext()) {
            nextButton.classList.remove(disabledClassName);
        }
        else {
            nextButton.classList.add(disabledClassName);
        }
    }
    embla
        .on('init', togglePrevNextButtonsState)
        .on('reInit', togglePrevNextButtonsState)
        .on('select', togglePrevNextButtonsState);
}
function addNavigation(embla, navigationElement) {
    navigationElement.innerHTML = '<span class="embla-button embla-button-prev"></span><span class="embla-button embla-button-next"></span>';
    const prevButton = navigationElement.querySelector('.embla-button-prev');
    const nextButton = navigationElement.querySelector('.embla-button-next');
    if (prevButton === null || nextButton === null) {
        return;
    }
    const scrollPrev = () => {
        embla.scrollPrev();
    };
    const scrollNext = () => {
        embla.scrollNext();
    };
    addTogglePrevNextButtonsActive(embla, prevButton, nextButton);
    prevButton.addEventListener('click', scrollPrev, false);
    nextButton.addEventListener('click', scrollNext, false);
}
function removeNavigation(navigationElement) {
    return () => {
        navigationElement.innerHTML = '';
    };
}
