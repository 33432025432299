"use strict";
(() => {
    const openGalleryElement = document.getElementById('gallery-link');
    if (openGalleryElement === null) {
        return;
    }
    openGalleryElement.addEventListener('click', (event) => {
        event.preventDefault();
        const firstGalleryImage = document.querySelector('.primary-box .images-list .image-list-item .gallery-image');
        if (firstGalleryImage) {
            firstGalleryImage.click();
        }
    });
})();
