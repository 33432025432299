"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ElementViewportObserver {
    static observeElement(element, callback) {
        if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window) {
            const observer = new IntersectionObserver(((entries) => {
                if (entries[0].isIntersecting === true) {
                    callback(element);
                    observer.disconnect();
                }
            }), { threshold: [0] });
            observer.observe(element);
        }
        else {
            callback(element);
        }
    }
}
exports.default = ElementViewportObserver;
