"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Tooltip {
    constructor(targetElement, initialize = true) {
        this.targetElement = targetElement;
        this.initialize = initialize;
        if (this.initialize) {
            this.create();
        }
    }
    create() {
        this.targetElement.classList.add('tooltip-active');
        this.targetElement.addEventListener('click', Tooltip.handleClick);
    }
    destroy() {
        this.targetElement.classList.remove('tooltip-active');
        this.targetElement.removeEventListener('click', Tooltip.handleClick);
    }
    static handleClick(event) {
        event.preventDefault();
        const target = event.currentTarget;
        const { body } = document;
        function open(tooltip) {
            tooltip.classList.add('tooltip-open');
            body.classList.add('tooltip-open');
            const leftOffset = tooltip.getBoundingClientRect().left;
            const openToRight = window.innerWidth - leftOffset < 300;
            const openToLeft = leftOffset < 140;
            if (openToRight) {
                tooltip.classList.add('right');
            }
            if (openToLeft) {
                tooltip.classList.add('left');
            }
        }
        function close(tooltip) {
            tooltip.classList.remove('tooltip-open', 'right', 'left');
            body.classList.remove('tooltip-open');
        }
        if (target) {
            const isTooltipOpen = target.classList.contains('tooltip-open');
            if (isTooltipOpen) {
                close(target);
            }
            else {
                open(target);
            }
        }
    }
}
exports.default = Tooltip;
