"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ScreenSize {
    static isLargeScreen() {
        return this.getBreakpointName() === 'large-screen';
    }
    static isMediumScreen() {
        return this.getBreakpointName() === 'medium-screen';
    }
    static isSmallScreen() {
        return this.getBreakpointName() === 'small-screen';
    }
    static getBreakpointName() {
        return window
            .getComputedStyle(document.body, ':after')
            .getPropertyValue('content')
            .replace(/"/g, '');
    }
}
exports.default = ScreenSize;
