"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class InputValidator {
    static isEmailValid(emailAddress) {
        const emailReg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,13}$/i;
        return emailReg.test(emailAddress);
    }
    static validateEmailInput(input, message = 'E-mail není ve správném tvaru.') {
        if (this.isEmailValid(input.value) === true) {
            this.removeError(input);
        }
        else {
            this.addError(input, message);
        }
    }
    static isPhoneValid(phone) {
        const phoneReg = /^\d{9}$/;
        return phoneReg.test(phone);
    }
    static validatePhoneInput(input, message = 'Telefon není ve správném tvaru.') {
        if (this.isPhoneValid(input.value) === true) {
            this.removeError(input);
        }
        else {
            this.addError(input, message);
        }
    }
    static isTextValid(value) {
        return value.length > 2;
    }
    static validateTextInput(input, message = 'Vyplňte prosím toto pole.') {
        if (this.isTextValid(input.value) === true) {
            this.removeError(input);
        }
        else {
            this.addError(input, message);
        }
    }
    static addError(input, message) {
        const parent = input.parentElement;
        const errorMessage = parent === null || parent === void 0 ? void 0 : parent.querySelector('.error-message');
        if (parent && !errorMessage) {
            input.classList.add('error');
            parent.insertAdjacentHTML('beforeend', `<span class="error error-message">${message}</span>`);
        }
    }
    static removeError(input) {
        const parent = input.closest('.form-row');
        const errorMessage = parent === null || parent === void 0 ? void 0 : parent.querySelector('.error-message');
        input.classList.remove('error');
        if (errorMessage) {
            errorMessage.remove();
        }
    }
}
exports.default = InputValidator;
