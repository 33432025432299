"use strict";
(() => {
    const youtubeVideos = Array.from(document.querySelectorAll('.youtube-video'));
    youtubeVideos.forEach((video) => {
        const videoId = video.dataset.embed;
        if (videoId === undefined) {
            return;
        }
        const image = new Image();
        image.src = `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
        image.addEventListener('load', () => {
            video.appendChild(image);
        });
        video.addEventListener('click', () => {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '');
            iframe.setAttribute('allow', 'autoplay');
            iframe.setAttribute('src', `https://www.youtube.com/embed/${video.dataset.embed}?rel=0&showinfo=0&autoplay=1`);
            video.innerHTML = '';
            video.appendChild(iframe);
        });
    });
})();
