"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = handlePageshowBackForwardAction;
function handlePageshowBackForwardAction(callback) {
    window.addEventListener('pageshow', (event) => {
        if (event.persisted === true
            || (window.performance !== undefined
                && window.performance.getEntriesByType('navigation')[0] !== undefined
                && window.performance.getEntriesByType('navigation')[0].type === 'back_forward')) {
            callback();
        }
    });
}
