"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class GtmContainer {
    static isValidGtmId(gtmId) {
        return gtmId === GtmContainer.GTM_DEV_ID || gtmId === GtmContainer.GTM_PREPROD_ID || gtmId === GtmContainer.GTM_PROD_ID;
    }
    static checkGtmExist() {
        return window.google_tag_manager !== undefined;
    }
}
GtmContainer.GTM_DEV_ID = 'GTM-M4P67LT';
GtmContainer.GTM_PREPROD_ID = 'GTM-P8TDSH2';
GtmContainer.GTM_PROD_ID = 'GTM-K2F6WCD';
exports.default = GtmContainer;
