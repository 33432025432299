"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const setCookies_1 = require("@web/cookiesSettings/setCookies");
(() => {
    const gdprPageOptionsList = document.getElementById('gdpr-cookies-policy-options-GdprPage');
    const gdprPageConfirmButton = document.getElementById('gdpr-cookies-settings-page-confirm');
    if (gdprPageConfirmButton === null || gdprPageOptionsList === null) {
        return;
    }
    gdprPageConfirmButton.addEventListener('click', (event) => {
        event.preventDefault();
        (0, setCookies_1.setCookies)(setCookies_1.CookiesEnum.SELECTED, gdprPageOptionsList, () => {
            const gdprPageConfirmButtonParentNode = gdprPageConfirmButton.parentNode;
            if (gdprPageConfirmButtonParentNode === null) {
                return;
            }
            const successMessage = document.createElement('div');
            successMessage.classList.add('alert', 'alert-success');
            successMessage.innerHTML = 'Vaše nastavení cookies bylo uloženo.';
            gdprPageConfirmButtonParentNode.insertBefore(successMessage, gdprPageConfirmButton);
            setTimeout(() => {
                successMessage.remove();
            }, 2000);
        });
    });
})();
