"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ElementAttributeImageSwitcher {
    static switch(element, isBackground, isSmallScreen) {
        const imageElement = element;
        const imageData = imageElement.dataset.image;
        if (imageData) {
            const src = this.getImageSrcByPixelRatio(imageData, isSmallScreen);
            if (src) {
                this.loadImage(src)
                    .then((resolvedSrc) => {
                    if (isBackground) {
                        imageElement.style.backgroundImage = `url(${resolvedSrc})`;
                    }
                    else {
                        imageElement.setAttribute('src', resolvedSrc);
                    }
                }).catch((e) => {
                    console.warn(`Catch loadImage error | ${e}`);
                }).finally(() => {
                    imageElement.removeAttribute('data-image');
                });
            }
        }
    }
    static getImageSrcByPixelRatio(imageData, isSmallScreen) {
        const { devicePixelRatio } = window;
        const imgData = (() => {
            try {
                return JSON.parse(imageData);
            }
            catch (e) {
                return imageData;
            }
        })();
        if (typeof imgData === 'object' && imgData !== null) {
            const lowQualityImage = imgData.low;
            const highQualityImage = imgData.high;
            if (lowQualityImage && highQualityImage) {
                const isDisplayHighResolutionImageAllowed = devicePixelRatio > 1
                    && ((isSmallScreen === true && imgData.displayHighQualityImageOnMobile !== false)
                        || (isSmallScreen === false && imgData.displayHighQualityImageOnDesktop !== false));
                return isDisplayHighResolutionImageAllowed ? highQualityImage : lowQualityImage;
            }
        }
        else {
            return imgData;
        }
        return null;
    }
    static loadImage(src) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            const ts = Date.now();
            image.onload = () => {
                resolve(src);
            };
            image.onerror = () => {
                if (Date.now() - ts < 2000) {
                    setTimeout(() => {
                        image.src = src;
                    }, 1000);
                }
                else {
                    reject(new Error(`Image ${src} can't be loaded.`));
                }
            };
            image.src = src;
        });
    }
}
exports.default = ElementAttributeImageSwitcher;
