"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addPager = addPager;
exports.removePager = removePager;
function addPager(embla, pagerElement) {
    let dotElements = [];
    const defaultLeftPosition = 32;
    function addDotButtonsWithClickHandlers() {
        pagerElement.innerHTML = embla.scrollSnapList().map(() => '<span class="embla-dot"></span>').join('');
        dotElements = Array.from(pagerElement.querySelectorAll('.embla-dot'));
        dotElements.forEach((dotElement, index) => {
            dotElement.addEventListener('click', () => {
                embla.scrollTo(index);
            }, false);
        });
    }
    function toggleDotButtonsActive() {
        const previousIndex = embla.previousScrollSnap();
        const selectedIndex = embla.selectedScrollSnap();
        const previousDotElement = dotElements[previousIndex];
        const selectedDotElement = dotElements[selectedIndex];
        const left = defaultLeftPosition - ((defaultLeftPosition / 2) * selectedIndex);
        if (dotElements.length > 5) {
            dotElements.forEach((dotElement) => {
                dotElement.style.setProperty('left', `${left}px`);
            });
        }
        if (previousDotElement) {
            previousDotElement.classList.remove('embla-dot-selected');
        }
        if (selectedDotElement) {
            selectedDotElement.classList.add('embla-dot-selected');
        }
    }
    embla
        .on('init', addDotButtonsWithClickHandlers)
        .on('init', toggleDotButtonsActive)
        .on('reInit', addDotButtonsWithClickHandlers)
        .on('reInit', toggleDotButtonsActive)
        .on('select', toggleDotButtonsActive);
}
function removePager(pagerElement) {
    return () => {
        pagerElement.innerHTML = '';
    };
}
