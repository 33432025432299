"use strict";
(() => {
    function redirectToGateway() {
        const cardPaymentLink = document.getElementById('card-payment-link');
        if (cardPaymentLink === null) {
            return;
        }
        const { paymentUrl } = cardPaymentLink.dataset;
        if (paymentUrl) {
            window.location.replace(paymentUrl);
        }
    }
    window.addEventListener('load', () => {
        setTimeout(redirectToGateway, 1000);
    });
    setTimeout(redirectToGateway, 5000);
})();
