"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consentGrantedAdStorage = exports.ConsentValueEnum = exports.consentStorageObject = void 0;
exports.consentStorageObject = {};
var ConsentValueEnum;
(function (ConsentValueEnum) {
    ConsentValueEnum["DENIED"] = "denied";
    ConsentValueEnum["GRANTED"] = "granted";
})(ConsentValueEnum || (exports.ConsentValueEnum = ConsentValueEnum = {}));
const consentGrantedAdStorage = (consentStorage) => {
    try {
        gtag('consent', 'update', consentStorage);
    }
    catch (e) {
        console.error(e);
    }
};
exports.consentGrantedAdStorage = consentGrantedAdStorage;
